import $ from 'jquery';
import whatInput from 'what-input';

//Mobile Menu
$(document).ready(function(){

    $('.nav-menu').click(function(){
        $('#nav-icon1,#nav-icon2,#nav-icon3,#nav-icon4').toggleClass('open');
        $('.mobile-navigation').toggleClass('mobile-toggle');
        $('.mobile-navigation .top-bar-right ').toggleClass('open-menu');
        $('header').toggleClass('full-height-menu');
        $('header').toggleClass('full-height');
        $('.hamburger-container').toggleClass('dark-background');
        $('.mobile-navigation').toggleClass('remove-background');


        setTimeout(function() {
            $('.mobile-background-image').fadeToggle('slow');
        }, 300);

    });

});

$('.sub-menu').hide();
$('.top-bar .top-bar-right li ').click(function(){
    $(this).children("ul").slideToggle();

});

// Sticky hamburger menu on tablet and mobile.
$(document).ready(function() {
    $(window).scroll(function() {
        if ($(document).scrollTop() > 26) {
            $('.hamburger-container').addClass('hambugrer-sticky');
            $('header').addClass('reduce-header');
        }
        else {
            $('.hamburger-container').removeClass('hambugrer-sticky');
            $('header').removeClass('reduce-header');
        }
    })
})